import React from 'react';

function importAll(r) {
	let images = {};
	r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
	return images;
}
const Job = ({ darkMode, name }) => {
	const images = importAll(require.context('../../../public/jobs/', false, /\.(png|jpe?g|svg)$/));
	console.log(images)
	return (
		<section className="job" id="job">
			<h3>
				Customers
			</h3>
			<div className="details">
				<div className="item text-muted">
					
					<div className={darkMode.value ? "customers" : "customers customers-invert"}>						
						<img src={images['vivo.png']} alt="Vivo" />
						<img src={images['telefonica_.png']} alt="Telefonica" />
						<img src={images['tim.png']} alt="Tim" />
						<img src={images['mercadolivre.png']} alt="Mercado Livre" />
						<img src={images['nttdata_.png']} alt="NttData" />
						<img src={images['kraftheinz.png']} alt="Kraft Heinz" />
						<img src={images['mutant_.png']} alt="Mutant" />
						<img src={images['ambev.png']} alt="Ambev" />
						<img src={images['globo.png']} alt="Globo" />
						<img src={images['veloe.png']} alt="Veloe" />
						<img src={images['volvo.png']} alt="Volvo" />
						<img src={images['puma.png']} alt="Puma" />
						<img src={images['resmed.png']} alt="ResMed" />
						<img src={images['haoc.png']} alt="Hospital Alemão Oswaldo Cruz" />
						<img src={images['unigranrio.png']} alt="UnigranRio" />
						<img src={images['swarovski.png']} alt="Swarovski" />
						<img src={images['divena.png']} alt="Divena" />
						<img src={images['red.png']} alt="Red Innovations" />
						<img src={images['gp1.png']} alt="GP1" />
					</div>

				</div>
			</div>
		</section>
	);
};
export { Job };
